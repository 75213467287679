/* You can add global styles to this file, and also import other style files */

/* @import "~@angular/material/prebuilt-themes/indigo-pink.css"; */
/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}

:root {
  --black: #000;
  --white: #fff;
  --offwhite: #e9eaef;
  --blue: #01163a;
  --identified: #a465e4;
  --voided: #fff;
  --open: #eec315;
  --responseoverdue: #f67692fc;
  --closed: #2cc307;
  --awaiting_response: #eec315;
  --awaiting_response_approval: #eec315;
  --awaiting_response_acceptance: #eec315;
  --awaiting_request_approval: #eec315;
  --awaiting_closeout: #eec315;
  --overdue: #f11111;
  --additionalinfo: #eec315;
  --proposetoclose: #eec315;
  --reset: red;
  --neutral: #4a4b4c;
  --save: #198754;
  --add: #4a4b4c;
  --primary: #fff;
  --background: #DBDBDB;
  --button: #305792;
  --buttonhover: #6A93D1;
  --warn: #494949;
  --secondary: #000;
  --text: #000;
  --shadow: #c1c1c1;
  --identifiedBackgroundIcon: url("/assets/icons/Identified.svg");
  --openBackgroundIcon: url("/assets/icons/Open.svg");
  --closeoutOverdueBackgroundIcon: url("/assets/icons/CloseoutOverdue.svg");
  --responseOverdueBackgroundIcon: url("/assets/icons/ResponseOverdue.svg");
  --closedBackgroundIcon: url("/assets/icons/Closed.svg");
}

body {
  min-height: 100vh;
  overflow-x: hidden;
}

.btn-success {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
  margin-right: 5px !important;
  font-weight: 400;
  box-shadow: 0 0 5pt 2pt #d3d3d3 !important;
  outline-width: 0px !important;
}

  .btn-success:hover {
    color: #157347;
    background-color: #fff;
    border-color: #146c43;
  }

.btn-accept {
  color: #fff !important;
  background-color: #198754;
  border-color: #198754;
  margin-right: 5px !important;
  font-weight: 400;
  box-shadow: 0 0 5pt 2pt #d3d3d3 !important;
  outline-width: 0px !important;
}

  .btn-accept:hover {
    color: #198754 !important;
    background-color: #fff;
    border-color: #198754;
  }

.btn-rejected {
  color: #fff !important;
  background-color: #d75b78;
  border-color: #d75b78;
  margin-right: 5px !important;
  font-weight: 400;
  box-shadow: 0 0 5pt 2pt #d3d3d3 !important;
  outline-width: 0px !important;
}

  .btn-rejected:hover {
    color: #d75b78 !important;
    background-color: #fff;
    border-color: #d75b78;
  }

  .btn-success.disabled, .btn-success:disabled, .btn-accept.disabled, .btn-rejected.disabled {
    color: #fff !important;
  }

.btn-primary {
  color: #fff;
  background-color: #009EF7;
  border-color: #fff;
  margin-right: 5px !important;
  font-weight: 400;
  box-shadow: 0 0 5pt 2pt #d3d3d3 !important;
  outline-width: 0px !important;
}

  .btn-secondary:hover, .btn-primary:hover {
    color: #5c636a;
    background-color: #fff;
    border-color: #565e64;
  }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  box-shadow: 0 0 5pt 2pt #d3d3d3 !important;
  outline-width: 0px !important;
}

.btn-success .material-icons, .btn-primary .material-icons, .btn-accept .material-icons, .btn-rejected .material-icons {
  font-size: 20px !important;
  margin-right: 5px !important;
}

.btn-secondary .material-icons {
  font-size: 22px !important;
  margin-right: 5px !important;
}
/* ====================== Responsive Design ========================== */
/*@media (max-width: 991px) {
  .navigation {
    left: -300px;
  }

    .navigation.active {
      width: 300px;
      left: 0;
    }

  .main {
    width: 100%;
    left: 0;
  }

    .main.active {
      left: 300px;
    }

  .cardBox {
    grid-template-columns: repeat(2, 1fr);
  }
}
*/
@media (max-width: 768px) {
  .details {
    grid-template-columns: 1fr;
  }
  .client{
      display:none !important;
  }

  .recentOrders {
    overflow-x: auto;
  }

  .status.inProgress {
    white-space: nowrap;
  }
}

@media (max-width: 480px) {
  .cardBox {
    grid-template-columns: repeat(1, 1fr);
  }

  .cardHeader h2 {
    font-size: 20px;
  }

  .user {
    min-width: 40px;
  }
}

::-webkit-scrollbar {
  width: 0.4em;
  height: 0.4em;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--button);
  outline: 1px solid slategrey;
  border-radius: 10px;
}


#toggle:checked ~ .main {
  width: calc(100% - 70px);
  left: 70px;
}

.pPageHeading {
  font-weight: 400;
  font-size: 18px;
  color: #716c6c;
}

.anchorLink {
  text-decoration: none !important;
  cursor: pointer;
}

.anchorLinkwithblack {
  text-decoration: none !important;
  cursor: pointer;
  color: var(--black) !important;
}

.smallIconSize {
  font-size: 100% !important;
}

.mat-tab-body-wrapper {
  border-radius: 0;
  padding: 10px;
  box-shadow: 0 0 5pt 2pt #d3d3d3;
  outline-width: 0px;
  background-color: #fff;
}

.mat-tab-label-container {
  padding-left: 5px;
  padding-top: 5px;
}

.mat-tab-label:hover {
  background-color: #d2cfcf !important;
  color: #000;
  font-weight: 400 !important;
}

.mat-tab-label-active {
  background-color: rgb(255, 255, 255) !important;
  color: #000;
  box-shadow: 0 0 5pt 2pt #d3d3d3;
}

.paginatorSelect .mat-select, .paginatorSelect .mat-form-field-flex {
  width: 56px !important;
}


.mat-form-field {
  display: block !important;
  font-size: 12px !important;
}

.mat-icon-small-size {
  font-size: 18px;
  color: var(--button);
  cursor: pointer;
}

.mat-error {
  color: #e4736b;
}

.mat-form-field-subscript-wrapper {
  font-size: 85%;
}

.status {
  height: 12px;
  width: 12px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.status-open {
  background-color: var(--open);
}

.status-awaitingresponse {
  background-color: var(--awaiting_response);
}

.status-awaitingforresponseapproval {
  background-color: var(--awaiting_response_approval);
}

.status-awaitingforresponseacceptance {
  background-color: var(--awaiting_response_acceptance);
}

.status-awaitingforrequestapproval {
  background-color: var(--awaiting_request_approval);
}

.status-awaitingforcloseout {
  background-color: var(--awaiting_closeout);
}

.status-identified {
  background-color: var(--identified);
}

.status-voided {
  background-color: var(--voided);
  border: 0.1rem solid #afafaf;
}

.status-closed {
  background-color: var(--closed);
}

.status-overdue {
  background-color: var(--overdue);
}
.status-responseoverdue {
  background-color: var(--responseoverdue);
}
.status-closeoutoverdue {
  background-color: var(--overdue);
}

.status-additionalinfo {
  background-color: var(--additionalinfo);
}

.status-proposetoclose {
  background-color: var(--proposetoclose);
}

.status-my, .status-request {
  background-color: #6f9304;
}

.status-response {
  background-color: var(--responseoverdue);
}
.status-closeout{
    background-color:var(--overdue)
}
.status-duetoday {
  background-color: #ff00004f;
}

.status-responseduetoday {
  background-color: rgb(255, 204, 0);
}

.status-closeoutduetoday{
  background-color: rgb(202 200 91);
}

.h500tab {
  height: 500px;
}

.mat-ink-bar {
  width: 0px !important;
}

.mat-input-element:disabled {
  color: inherit !important;
}

.toolbarFontSize {
  font-size: 12px !important;
}


.interfaceTab .mat-tab-body-wrapper {
  margin-top: -2px;
}

.interfaceTab .mat-tab-header {
  margin-left: -5px;
}

.mat-tab-label {
  opacity: 1 !important;
  height: 35px !important;
  padding: 0 24px;
  cursor: pointer;
  box-sizing: border-box;
  opacity: .6;
  min-width: 160px;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  position: relative;
}

.mat-elevation-z8 {
  box-shadow: none !important;
}

.scroll-action-item{
    height: 200px !important;
}

.scroller {
  overflow: auto !important;
  overflow-x: hidden !important;
  height: 300px;
  padding-bottom: 0.2rem;
  margin-right: 0px;
  margin-left: 0px;
}
.statusHeaderLabel {
  color: #666666;
  font-size: 9px;
  font-weight: 300;
}

.chkAccepted {
  padding-right: 10px;
}

.hintMessage {
  font-size: 0.8em;
  font-weight: 400;
  font-family: inherit;
}

.text-right {
  text-align: right;
}

.h500tabMin {
  min-height: 32rem;
  max-height: 34rem;
}
.h500tabrpt {
  min-height: 32rem;
  max-height: 32rem;
}

.h600tabMin {
  min-height: 34rem;
  max-height: 45rem;
}

.h700tabMin{
    min-height : 39.6rem;
max-height : 50rem;
}

.title-font {
  font-size: 1.15rem !important;
}

.heading-font {
  font-size: 1.3rem !important;
}

.subtitle-font {
  font-size: 0.85rem;
}

.content-font {
  font-size: 0.78rem;
}

.calendar-icon-btn {
  border: hidden;
  background-color: transparent;
  color: #646464;
}

/*UI adjustment for daterangepicker*/
.dateRange {
}

  .dateRange .mat-form-field-infix {
    padding: 0em !important;
    border-top: 0em !important;
  }

/*UI adjustment for datepicker*/
.datePicker {
}

  .datePicker .mat-form-field-infix {
    padding: 0em !important;
  }

.badge {
  font-weight: 600 !important;
  padding: 4px 8px;
}

.badge-voided {
  color: #212529;
  background-color: #fff;
  border: 0.1rem solid #afafaf;
}

/*-----------------------------------Button--------------------------------*/
.WIRbutton {
  margin-right: 5px !important;
  font-weight: 400;
  box-shadow: 0 0 5pt 2pt #d3d3d3 !important;
  outline-width: 0px !important;
  font-size: .875rem;
}

.WIRbutton-icons {
  font-size: 20px;
  margin-right: 5px !important;
}

.WIRbutton-icon-only {
  font-weight: 400;
  box-shadow: 0 0 5pt 2pt #d3d3d3 !important;
  outline-width: 0px !important;
  font-size: .875rem;
  min-width: 42px !important;
  padding: 0px !important;
}

.WIRbutton-circle {
  display: flex !important;
  justify-content: center !important;
  width: 32px !important;
  height: 32px !important;
  border: none !important;
  border-radius: 17px !important;
  color: #646464 !important;
  background-color: transparent !important;
  align-items: center !important;
}

.WIRbutton-icons-circle {
  font-size: 18px !important;
}

/*Save & Search*/
.WIRbutton-success {
  color: #fff;
  background-color: #198754;
  border-color: #6c757d;
}


  .WIRbutton-success.disabled, .WIRbutton-success:disabled {
    color: #fff;
    background-color: #198754;
    border-color: #198754;
  }

  .WIRbutton-success.disabled, .WIRbutton-success:disabled, .WIRbutton-accept.disabled, .WIRbutton-rejected.disabled, .WIRbutton-acceptAsPreliminary.disabled {
    color: #fff !important;
  }

.WIRbutton.disabled, .WIRbutton:disabled {
  opacity: .65 !important;
}

/*New & Add*/
.WIRbutton-primary {
  color: #fff;
  background-color: var(--button); /*#009EF7;*/
  border-color: #6c757d;
}

  .WIRbutton-primary:hover {
    color: #fff;
    background-color: #6A93D1 !important;
    border-color: #305792;
  }
  /*Back & Reset*/
  .WIRbutton-secondary {
    color: #000;
    background-color: #fff;
    border-color: #fff;
  }

/*Accept*/
.WIRbutton-accept {
  color: #fff !important;
  background-color: #198754;
  border-color: #198754;
}



/*Accept as preliminary*/
.WIRbutton-acceptAsPreliminary {
  color: #fff !important;
  background-color: #0495d7;
  border-color: #0495d7;
}

/*Reject*/
.WIRbutton-reject {
  color: #fff !important;
  background-color: #d75b78;
  border-color: #d75b78;
}

/*Copy*/
.WIRbutton-info {
  color: #fff;
  background-color: #818b8ed4;
  border-color: #818b8ed4;
}

/*Revision*/
.WIRbutton-revision {
  color: #fff;
  background-color: #818b8ed4;
  border-color: #818b8ed4;
}

.WIRbutton-export {
  color: #fff;
  background-color: var(--button); /*#009EF7;*/
  border-color: #6c757d;
}

  .WIRbutton-secondary:hover, .WIRbutton-accept:hover, .WIRbutton-success:hover, .WIRbutton-acceptAsPreliminary:hover,
  .WIRbutton-reject:hover, .WIRbutton-info:hover, .WIRbutton-revision:hover, .WIRbutton-export:hover {
    color: #5c636a !important;
    background-color: #fff;
    border-color: #565e64;
  }

/*-----------------------------------Icons--------------------------------*/
.WIRicons-menu {
  height: 24px;
  width: 24px;
}


/*-----------------------------------Text--------------------------------*/
.WIRlabel {
  font-size: 12px !important;
  font-weight: 400 !important;
}

.WIRvalue {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.WIRvalue-input {
  font-size: 12px !important;
  font-weight: 400 !important;
}

.WIRtitle-page {
  font-weight: 400 !important;
  font-size: 18px !important;
  color: #716c6c !important;
}

.WIRtitle-tab {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.hidecolumn {
  display: none !important;
}

.hide-blue-border .form-control:focus {
  border-color: transparent !important;
  box-shadow: none !important;
}

.theme-container {
  display: flex;
}

.themeoptions {
  height: 80px;
  width: 100px;
  border: solid 1px black;
  margin-right: 10px;
  cursor: pointer;
  background-color: var(--white);
}

  .themeoptions:hover {
    background-color: lightgray;
  }

.theme-img {
  height: 50px;
  border: solid;
  margin: 5px;
  margin-bottom: 0;
}

.theme-name {
  margin-left: 5px;
  margin-right: 5px;
}

.theme-header {
  height: 10px;
  border-bottom: 1px solid black;
}

.theme-sidebar {
  width: 25px;
  height: 35px;
}

.default {
  background: blue;
}

.light {
  background: #ded9d9;
}

.dark {
  background: black;
}

.checkmark {
  display: none;
  width: 22px;
  height: 22px;
  margin-left: 75px;
  margin-top: -22px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);
}

.checkmark_circle {
  position: absolute;
  width: 22px;
  height: 22px;
  background-color: green;
  border-radius: 11px;
  left: 0;
  top: 0;
}

.checkmark_stem {
  position: absolute;
  width: 3px;
  height: 9px;
  background-color: #fff;
  left: 11px;
  top: 6px;
}

.checkmark_kick {
  position: absolute;
  width: 3px;
  height: 3px;
  background-color: #fff;
  left: 8px;
  top: 12px;
}

.checkmark-default, .checkmark-blue, .checkmark-dark {
  display: block;
}

.theme-main {
  width: 58px;
  height: 35px;
}

.theme-submain1 {
  margin: 5px;
  padding: 2px;
  background: #d9ded9;
}

.theme-dflex {
  display: flex;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #ffffff !important;
  opacity: 1;
}

.nodatafoundimgsmall {
  height: 100px;
}

.WIRbutton-success.disabled:hover,
.WIRbutton-success:disabled:hover {
  background-color: #198754 !important;
  color: #fff !important;
}

.WIRbutton-primary.disabled,
.WIRbutton-primary:disabled {
  color: var(--text);
  background-color: var(--button);
  border-color: var(--button);
}

  .WIRbutton-primary.disabled:hover,
  .WIRbutton-primary:disabled:hover {
    background-color: var(--button) !important;
    color: #fff !important;
  }

.WIRbutton-secondary.disabled,
.WIRbutton-secondary:disabled {
  color: #000 !important;
  background-color: #fff !important;
  border-color: #fff;
}

  .WIRbutton-secondary.disabled:hover,
  .WIRbutton-secondary:disabled:hover {
    background-color: #fff !important;
    color: #000 !important;
  }

.WIRbutton-accept.disabled,
.WIRbutton-accept:disabled {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}


.WIRbutton-acceptAsPreliminary.disabled,
.WIRbutton-acceptAsPreliminary:disabled {
  color: #fff;
  background-color: #0495d7;
  border-color: #0495d7;
}


.WIRbutton-accept.disabled:hover,
.WIRbutton-accept:disabled:hover {
  background-color: #198754 !important;
  color: #fff !important;
}

.WIRbutton-acceptAsPreliminary.disabled:hover,
.WIRbutton-acceptAsPreliminary:disabled:hover {
  background-color: #0495d7 !important;
  color: #fff !important;
}

.WIRbutton-reject.disabled,
.WIRbutton-reject:disabled {
  color: #fff;
  background-color: #d75b78;
  border-color: #d75b78;
}

  .WIRbutton-reject.disabled:hover,
  .WIRbutton-reject:disabled:hover {
    background-color: #d75b78 !important;
    color: #fff !important;
  }

.WIRbutton-info.disabled,
.WIRbutton-info:disabled {
  color: #fff;
  background-color: #5bc0de;
  border-color: #5bc0de;
}

  .WIRbutton-info.disabled:hover,
  .WIRbutton-info:disabled:hover {
    background-color: #5bc0de !important;
    color: #fff !important;
  }

.WIRbutton-revision.disabled,
.WIRbutton-revision:disabled {
  color: #fff;
  background-color: #5f9ea0;
  border-color: #5f9ea0;
}

  .WIRbutton-revision.disabled:hover,
  .WIRbutton-revision:disabled:hover {
    background-color: #5f9ea0 !important;
    color: #fff !important;
  }

.WIRbutton-primary.disabled, .WIRbutton-primary:disabled,
.WIRbutton-accept.disabled, .WIRbutton-accept:disabled,
.WIRbutton-acceptAsPreliminary.disabled, .WIRbutton-acceptAsPreliminary:disabled,
.WIRbutton-reject.disabled, .WIRbutton-reject:disabled,
.WIRbutton-info.disabled, .WIRbutton-info:disabled,
.WIRbutton-revision.disabled, .WIRbutton-revision:disabled {
  color: #fff !important;
}

.cdk-overlay-pane {
  transform: translateX(0px) translateY(20px) !important;
}

.mat-tooltip {
  margin: 0 14px 0 14px !important;
  transform: translateX(0px) translateY(-20px) !important;
}

.mat-elevation-z8 tr:hover {
  color: #212529 !important;
  background-color: rgb(212 203 203 / 8%) !important;
}

.mat-elevation-z8 mat-row:hover {
  color: #212529 !important;
  background-color: rgb(212 203 203 / 8%) !important;
}

.mat-select-panel {
  min-width: 100% !important;
}

.page-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page-title-name{
    font-size:1.4rem;
}

#stdrpt, #sumrpt, #interfacesheet, #pdfTable, #momsheet {
  visibility:hidden;
}

.mat-cell,mat-footer-cell {
  font-size: 0.75rem !important;
}

.stdrpt, .interfacesheet, .momsheet {
  margin-left: 20px;
  margin-right: 20px;
  width: 90%;
}

#customers, #status {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 50%;
  border: solid !important;
}

#status {
  width: 100%;
}

  #customers td, #customers th, #status td, #status th {
    border: 1px solid #000;
    padding: 8px;
  }

  #customers th, #status th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #dcdcdc;
    color: var(--black);
  }

.heading1 {
  display: block;
  padding: 10px;
  text-align: center;
  font-size: 1.8rem;
  font-weight: 500;
}

.heading2 {
  display: block;
  padding: 10px;
  text-align: center;
  font-weight: 500;
  font-size: 1.2rem;
}

.subheading {
  font-weight: 500;
  font-size: 1.2rem;
}

.heading3 {
  display: block;
  padding: 10px;
  text-align: center;
  font-weight: 500;
  font-size: 1.2rem;
}

.cardBox {
  background: #fff;
  border-radius: 0;
  width: 80%;
  border: #6c757d 1px solid;
}


.cardBox-width {
  width: 100% !important;
}

.titleHeader {
  font-size: .9375rem;
}

.freeTextbox {
  height: 30px;
  width: 100%;
}
.table_header_size{
    font-size:0.80rem;
}

.mat-header-row {
  height: 1.75rem !important;
}

.mat-header-cell {
  color: rgba(0, 0, 0) !important;
}

.fw-500 {
  font-weight: 500;
}

.fw-400 {
  font-weight: 400;
}

.chartcss {
  display: contents;
  text-align: -webkit-center;
}

.enable-line-break {
  white-space:pre-wrap;
}
.paginatorSelect {
  position: absolute !important;
  bottom: 3px !important;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap-reverse;
  width: 100%;
}

td.mat-cell{
    height: 2.5rem !important;
}

.mat-card {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  display: block;
  position: relative;
  padding: 10px !important;
  border-radius: 4px;
}

.material-symbols-outlined {
  font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 24;
}
.mat-typography p {
  margin: 0 0 6px;
}
.mat-button-disabled .mat-icon-small-size{
    cursor:not-allowed;
}
.mat-header-row th {
  position: sticky;
  top: 0;
  background: white;
  opacity: 100;
  z-index:1;
}
table.mat-table {
  width: 100%;
}
.column-border {
  border-left: 1px solid var(--border-color);
}
.mat-table-sticky {
  background: var(--white);
  opacity: 1;
  border-right: 1px solid var(--border-color);
  border-left: 1px solid var(--border-color);
}

.action-button {
  color: var(--button) !important;
}

  .action-button:hover {
    color: var(--button) !important;
  }

  .action-button:disabled,
  .action-button[disabled] {
    color: var(--buttonhover) !important;
  }

.button-icon-circle {
  display: flex !important;
  justify-content: center !important;
  width: 24px !important;
  height: 24px !important;
  border: none !important;
  border-radius: 17px !important;
  background-color: transparent !important;
  color: #646464;
}
tr.mat-row{
    height:0 !important;
}
.refresh {
  height: 1.1rem;
  margin-right: 0.2rem;
  cursor: pointer;
}
.requestor-self,.provider {
  padding: 0 0.2rem 0.1rem 0.2rem;
}
  .provider:hover, .requestor:hover, .requestor-self:hover {
    transform: scale(1.3);
  }
.table {
  table-layout: fixed;
  width: 100%;
}
.update-button {
  margin-left: 3px !important;
}

.dashboardModule {
  border-radius: 1%;
  box-shadow: 5px 5px 5px #b1acac;
  background-color: #fff;
  margin: 0 0 15px 0;
  border-radius: 4px;
}

.moduleSelectionBar {
  text-align: right;
  padding-right: 0px;
  height: 25px !important;
}

  .moduleSelectionBar .mat-button-toggle-checked {
    background-color: var(--button) !important;
    color: #fff;
  }

.moduleTab {
  border-radius: 0px !important;
}

  .moduleTab .mat-button-toggle-button .mat-button-toggle-label-content {
    height: 100% !important;
    line-height: 22px !important;
    padding: 0 12px !important;
  }

  .linkCursor{
    cursor: pointer !important;
  }

  .scroller-list-Screen {
    overflow: auto !important;
    overflow-x: hidden !important;
    height: 450px;
    padding-bottom: 0.2rem;
    margin-right: 0px;
    margin-left: 0px;
  }

  .scroller-child-Screen {
    overflow: auto !important;
    overflow-x: hidden !important;
    height: 260px;
    padding-bottom: 0.2rem;
    margin-right: 0px;
    margin-left: 0px;
  }

.hidecontrolsfirst{
  visibility:hidden;
}
